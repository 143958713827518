/* requires:
polyfill.js
*/

const allReviews = {
  allReviewsBaseUrl: 'cart_domain_replaced_during_build/ProductRatingReviewApi/getReviews?',

  init: () => {
    allReviews.initAllReviews();
  },

  initAllReviews: () => {
    const allReviewsCollapseEl = document.querySelector('#all-reviews-collapse');
    const allReviewsAnchor = document.querySelector('.all-reviews-anchor');
    allReviewsAnchor.innerHTML = 'Show All Reviews (+)';
    allReviewsAnchor.addEventListener('click', (event) => {
      if (allReviewsAnchor.innerHTML === 'Hide All Reviews (-)') {
        const productId = allReviewsCollapseEl.dataset.productId;
        allReviews.getReviews(productId);
        setTimeout(() => {
          window.location.href = '#all-reviews-collapse';
        }, 400);
      } else {
        window.location.href = '#reviews-and-ratings';
      }
    });
    allReviewsCollapseEl.addEventListener('show.bs.collapse', (event) => {
      allReviewsAnchor.innerHTML = 'Hide All Reviews (-)';
    });
    allReviewsCollapseEl.addEventListener('hide.bs.collapse', (event) => {
      allReviewsAnchor.innerHTML = 'Show All Reviews (+)';
    });
  },

  getReviews: (productId) => {
    const url = `${allReviews.allReviewsBaseUrl}productId=${productId}&rating=%7B%22isTrusted%22:true%7D`;
    fetch(url, { credentials: 'include', mode: 'cors' })
      .then(checkFetchStatus)
      .then((res) => {
        if (res) {
          allReviews.renderReviews(res.data);
          allReviews.renderPagination(res.data);
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  },

  renderReviews: (reviews) => {
    const customerReviewsWrap = document.querySelector('.customer-reviews');
    customerReviewsWrap.innerHTML = '';
    const reviewsList = reviews.reviews;

    reviewsList.forEach((reviewObject) => {
      // Create full review section with read more if review is longer than 500 characters
      const renderedReviewSection = allReviews.renderReview(reviewObject);
      // Create comments section with or without comments based on if reply exists
      const renderedCommentsSection = allReviews.renderCommentsContent(reviewObject);
      // Create review element
      const reviewEl = document.createElement('div');
      reviewEl.classList.add('review');
      reviewEl.innerHTML = `<div class="review-header mt-4">
    		<div class="review-stars mb-2">
    			${allReviews.renderStars(reviewObject.rating)}
    		</div>
    		<p class="review-title"><strong>${reviewObject.title}</strong></p>
        <p class="review-author">By: <strong>${reviewObject.userAlias}</strong> on ${reviewObject.createdAt}</p>
      </div>
      <div id="review-body-${reviewObject.id}" class="review-body mb-4">${renderedReviewSection}</div>
      <div id="review-comments-${
        reviewObject.id
      }" class="review-comments d-flex flex-column">${renderedCommentsSection}</div>
      `;

      // Append review element to customer reviews wrap
      customerReviewsWrap.appendChild(reviewEl);

      // Append show comments element to review element
      function initShowCommentsEventListener() {
        const showCommentsAnchor = document.querySelector(`#show-comments-anchor-${reviewObject.id}`);
        if (showCommentsAnchor) {
          // Add event listener to show comments anchor
          const showCommentsCollapse = document.querySelector(`#show-comments-collapse-${reviewObject.id}`);
          showCommentsCollapse.addEventListener('show.bs.collapse', (event) => {
            // Change show comments anchor text
            showCommentsAnchor.innerHTML = 'Hide Comments (-)';
          });
          showCommentsCollapse.addEventListener('hide.bs.collapse', (event) => {
            // Change show comments anchor text
            showCommentsAnchor.innerHTML = 'Show Comments (+)';
          });
        }
      }

      function initReadMoreEventListener() {
        const readMoreAnchor = document.querySelector(
          `#review-body-${reviewObject.id} .read-more-anchor-${reviewObject.id}`
        );
        if (readMoreAnchor) {
          // Add event listener to read more anchor
          readMoreAnchor.addEventListener('click', (event) => {
            // Change review body to full review
            document.querySelector(`#review-body-${reviewObject.id}`).innerHTML = allReviews.renderReview(
              reviewObject,
              true
            );
          });
        }
      }

      initReadMoreEventListener();
      initShowCommentsEventListener();
    });
  },

  renderCommentsContent: (reviewObject) => {
    let showCommentsEl;
    if (reviewObject.reply) {
      showCommentsEl = `
      <a class="show-comments-anchor me-auto me-lg-0 ms-lg-auto mb-4" role="button" aria-disabled="true" data-bs-toggle="collapse" href="#show-comments-collapse-${reviewObject.id}" aria-expanded="false" aria-controls="show-comments-collapse-${reviewObject.id}" id="show-comments-anchor-${reviewObject.id}">Show Comments (+)</a>
        <div class="collapse" id="show-comments-collapse-${reviewObject.id}">
          <div class="customer-comment ps-3 border-start border-3">
            <p class="customer-comment-title"><strong>Reply: ${reviewObject.title}</strong></p>
            <p class="customer-comment-author">By: <strong>StateFoodSafety.com</strong> on ${reviewObject.replyAt}</p>
            <p class="customer-comment-body">${reviewObject.reply}</p>
          </div>
        </div>`;
    } else {
      showCommentsEl = '';
    }
    return showCommentsEl;
  },

  renderReview: (reviewObject, expanded) => {
    const fullReview = reviewObject.review;
    const shortReview = reviewObject.review.substring(0, 450);
    const reviewLength = reviewObject.review.length;
    let renderedReviewSection = '';
    if (reviewLength > 450 && !expanded) {
      renderedReviewSection =
        shortReview +
        `... <a class="read-more-anchor-${reviewObject.id}" role="button" aria-disabled="true" href="#/">&raquo; Read More</a>`;
      return renderedReviewSection;
    } else {
      renderedReviewSection = fullReview;
      return renderedReviewSection;
    }
  },

  renderStars: (rating) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(
          '<svg class="svg" width="25" height="25" viewbox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#FEC008"></path></svg>'
        );
      } else {
        stars.push(
          '<svg class="svg" width="25" height="25" viewbox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z" fill="#CFCFCF"></path></svg>'
        );
      }
    }
    return stars.join('');
  },

  renderPagination: (reviews) => {
    const reviewsTotal = reviews.reviewsCount;
    const paginationWrap = document.querySelector('.pagination-nav');
    const reviewsPerPage = reviews.reviewsPerPage;
    const reviewsOffset = reviews.reviewsOffset;
    const reviewsList = reviews.reviews;
    let paginationText;
    if (reviewsTotal === reviewsOffset + 1) {
      paginationText = `${reviewsOffset + 1} of ${reviewsTotal}`;
    } else {
      paginationText = `${reviewsOffset + 1}-${reviewsOffset + reviewsList.length} of ${reviewsTotal}`;
    }
    const paginationNav = `
			<nav class="pagination-nav d-inline-block me-3" aria-label="Pagination">
				<ul class="pagination pagination-sm mb-0">
					<li class="page-item">
						<button class="page-link px-3 py-2 text-dark" aria-label="Previous" data-page="reviews-page-prev">
							<span aria-hidden="true">&laquo;</span>
						</button>
					</li>
					<li class="page-item">
						<button class="page-link px-3 py-2 text-dark" aria-label="Next" data-page="reviews-page-next">
							<span aria-hidden="true">&raquo;</span>
						</button>
					</li>
				</ul>
			</nav>`;

    if (reviewsTotal <= 5) {
      paginationWrap.innerHTML = paginationText;
    } else {
      paginationWrap.innerHTML = paginationNav + paginationText;
      const prevPageButton = document.querySelector('[data-page="reviews-page-prev"]');
      const nextPageButton = document.querySelector('[data-page="reviews-page-next"]');
      if (reviewsOffset === 0) {
        prevPageButton.disabled = true;
        prevPageButton.classList.add('bg-btn-disabled');
      } else {
        if (prevPageButton.classList.contains('bg-btn-disabled')) {
          prevPageButton.classList.remove('bg-btn-disabled');
        }
        prevPageButton.disabled = false;
      }
      if (reviewsOffset + reviewsPerPage >= reviewsTotal) {
        nextPageButton.disabled = true;
        nextPageButton.classList.add('bg-btn-disabled');
      } else {
        if (nextPageButton.classList.contains('bg-btn-disabled')) {
          nextPageButton.classList.remove('bg-btn-disabled');
        }
        nextPageButton.disabled = false;
      }
      prevPageButton.addEventListener('click', (event) => {
        allReviews.getReviewsPrev(reviewsOffset, reviewsPerPage);
      });
      nextPageButton.addEventListener('click', (event) => {
        allReviews.getReviewsNext(reviewsOffset, reviewsPerPage);
      });
    }
  },

  getReviewsPrev: (reviewsOffset, reviewsPerPage) => {
    const productId = document.querySelector('#all-reviews-collapse').dataset.productId;
    const offset = reviewsOffset - reviewsPerPage;
    const url = `${allReviews.allReviewsBaseUrl}productId=${productId}&rating=%7B%22isTrusted%22:true%7D&offset=${offset}`;
    fetch(url, { credentials: 'include', mode: 'cors' })
      .then(checkFetchStatus)
      .then((res) => {
        if (res) {
          allReviews.renderReviews(res.data);
          allReviews.renderPagination(res.data);
          window.location.href = '#all-reviews-collapse';
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  },

  getReviewsNext: (reviewsOffset, reviewsPerPage) => {
    const productId = document.querySelector('#all-reviews-collapse').dataset.productId;
    const offset = reviewsOffset + reviewsPerPage;
    const url = `${allReviews.allReviewsBaseUrl}productId=${productId}&rating=%7B%22isTrusted%22:true%7D&offset=${offset}`;
    fetch(url, { credentials: 'include', mode: 'cors' })
      .then(checkFetchStatus)
      .then((res) => {
        if (res) {
          allReviews.renderReviews(res.data);
          allReviews.renderPagination(res.data);
          window.location.href = '#all-reviews-collapse';
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  },
};

allReviews.init();
